import React, { createContext, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetEventDetails,
  useGetPantherEventDetails
} from "../../api/events";
import { useGetIncident, useGetPantherIncident } from "../../api/incidents";
import { buildUrl } from "../../utils/string.utils";
import EventDetails from "./eventDetails";
import {
  INCIDENT_URL,
  PANTHER_INCIDENT_URL
} from "../../constants/urls.constants";

export const EventDetailInfoProviderContext = createContext<any>({});

export function EventDetailInformationProvider() {
  const get = useGetEventDetails();
  const getIncident = useGetIncident();
  const [data, setData] = useState<any>({});

  const { incidentId, eventId, detectionId } = useParams();

  const load = useCallback(async () => {
    try {
      const response = await get(`${eventId}`, `${detectionId}`);
      setData(response);
    } catch (error: any) {
      console.error(
        `Error getting details of event ${eventId} - detection ${detectionId}, of incident ${incidentId}. Status ${error.status}. ${error}`
      );
    }
  }, [get, eventId, detectionId, incidentId]);

  const incidentURL = buildUrl(INCIDENT_URL, `${incidentId}`);
  const eventDetailProviderData = useMemo(
    () => ({
      incidentURL,
      getIncident,
      data,
      load
    }),
    [data, getIncident, incidentURL, load]
  );

  return (
    <EventDetailInfoProviderContext.Provider value={eventDetailProviderData}>
      <EventDetails />
    </EventDetailInfoProviderContext.Provider>
  );
}

export function PantherEventDetailInformationProvider() {
  const { incidentId = "", eventId = "", alertId = "" } = useParams();

  const get = useGetPantherEventDetails();
  const getIncident = useGetPantherIncident();
  const [data, setData] = useState<any>({});

  const load = useCallback(async () => {
    try {
      const response = await get(incidentId, eventId, alertId);
      setData(response);
    } catch (error: any) {
      console.error(
        `Error getting details of event ${eventId} - detection ${alertId}, of incident ${incidentId}. Status ${error.status}. ${error}`
      );
    }
  }, [get, eventId, alertId, incidentId]);

  const incidentURL = useMemo(
    () => buildUrl(PANTHER_INCIDENT_URL, `${incidentId}`),
    [incidentId]
  );
  const eventDetailProviderData = useMemo(
    () => ({
      incidentURL,
      getIncident,
      data,
      load,
      isSIEM: true
    }),
    [data, getIncident, incidentURL, load]
  );

  return (
    <EventDetailInfoProviderContext.Provider value={eventDetailProviderData}>
      <EventDetails />
    </EventDetailInfoProviderContext.Provider>
  );
}
