/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useMemo } from "react";
import "./event.scss";
import EventDefaultActionBar from "./actionBar/eventDefaultActionBar";
import AnalystNotes from "./analystNotes/analystNotes";
import FormatDate from "../formatDate/formatDate";
import Accordion from "../components/accordion/accordion";
import { IEventProps } from "./event.types";
import { GENERIC_EVENT } from "../constants/common.constants";
import Icon from "../components/icon/icon";
import DetailLine from "./detailLine/detailLine";
import SessionContext from "../contexts/session.context";
import { IncidentInfoProviderContext } from "../incident/incidentInformationProvider";
import Alerts from "./alerts/alerts";

export default function Event({ data, title }: IEventProps) {
  const { isMdrRole } = useContext(SessionContext);
  const {
    created,
    event_id,
    analyst_note: analystNotes,
    is_relevant,
    client_visible,
    url,
    detections,
    triggering_rule,
    details,
    alerts,
    event_summary
  } = data;
  const { isSIEM } = useContext(IncidentInfoProviderContext);
  const alertId = useMemo(() => {
    if (alerts) {
      return alerts[0].alert_id;
    }
    return "";
  }, [alerts]);

  const eventTitle = useMemo(() => `${title} event`, [title]);
  const icon = useMemo(() => <Icon image={GENERIC_EVENT} />, []);

  const showAnalystNotes = useMemo(
    () =>
      isMdrRole ||
      (Reflect.has(analystNotes, "Note") && !!analystNotes.Note.length),
    [analystNotes, isMdrRole]
  );

  const timestamp = useMemo(
    () => new FormatDate(created).dateWithTime,
    [created]
  );

  const headerTitle = useMemo(
    () => (
      <div className="eventContainer__title">
        <p>{eventTitle}</p>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{timestamp}</label>
      </div>
    ),
    [eventTitle, timestamp]
  );

  const actionBar = useMemo(
    () =>
      isMdrRole ? (
        <EventDefaultActionBar
          detectionId={detections ? detections[0] : ""}
          alertId={alertId}
          edrUrl={url}
          eventId={event_id}
          isRelevant={is_relevant || client_visible}
        />
      ) : null,
    [alertId, client_visible, detections, event_id, isMdrRole, is_relevant, url]
  );
  return (
    <Accordion icon={icon} title={headerTitle} actionBar={actionBar} compressed>
      <div className="eventContainer">
        <div className="eventContainerColumn" />
        <div className="eventContainer__details">
          <div className="eventDataContainer">
            <div className="genericEventDetailsContainer">
              {isMdrRole && triggering_rule && (
                <>
                  <label>Triggering event rule</label>
                  <ul className="genericEventDetailsContainerList">
                    {triggering_rule.map((rule, index) => (
                      <li key={index}>{rule}</li>
                    ))}
                  </ul>
                </>
              )}

              {!isSIEM && <DetailLine data={details || {}} />}
            </div>
            {isSIEM && (
              <div className="genericEventDetailsContainer ">
                {alerts && (
                  <>
                    <label>Suspicious indicator</label>
                    <div className="genericEventDetailsContainerList genericEventDetailsContainerList--alerts">
                      <Alerts alerts={alerts} isMdrRole={isMdrRole} />
                    </div>
                  </>
                )}

                <div className="genericEventDetailsContainer">
                  <DetailLine data={event_summary || {}} />
                </div>
                <Accordion
                  title={<p className="section-title">Event Details</p>}
                  compressed
                  closed
                >
                  <div className="genericEventDetailsContainer">
                    <DetailLine data={details || {}} />
                  </div>
                </Accordion>
              </div>
            )}
          </div>
        </div>
        {showAnalystNotes && (
          <div className="eventContainer__analystNotes">
            <AnalystNotes data={analystNotes} eventId={event_id} />
          </div>
        )}
      </div>
    </Accordion>
  );
}
