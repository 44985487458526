import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import TextArea from "../components/textArea/textArea";
import { WIDTH } from "../constants/common.constants";
import "./closeIncidentForm.scss";

export const closeIncidentValidationSchema = Yup.object().shape({
  closeFinalDisposition: Yup.string().required(
    "Please provide a reason for closure"
  )
});

// this is a custom wrapper for formik states.
function customTextArea({ field, form, label, ...props }: any) {
  return (
    <div className="textAreaContainer">
      {label && <label htmlFor={field.name}>{label}</label>}
      <TextArea
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={field.name}
        className={
          form.errors[field.name] && form.touched[field.name] ? "has-error" : ""
        }
      />
    </div>
  );
}

export default function CloseIncidentForm({
  confirmMessage,
  onSubmit,
  initialValues
}: any) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={closeIncidentValidationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ handleSubmit, errors, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} id="closeIncidentForm">
            <Field
              name="closeFinalDisposition"
              label="Reason for Closure"
              error={errors.closeFinalDisposition}
              touched
              width={WIDTH.max}
              component={customTextArea}
              onChange={(event: any) =>
                setFieldValue(
                  "closeFinalDisposition",
                  event?.target?.value || ""
                )
              }
            />
          </Form>
          <p className="close-incident-confirm-message">{confirmMessage}</p>
        </>
      )}
    </Formik>
  );
}
