/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useContext, useMemo, useState } from "react";
import "./incidentDetails.scss";
import { Field } from "formik";
import {
  SEVERITY,
  WIDTH,
  MESSAGE,
  TEXT_WHITE_ICON,
  CRITICAL_ICON,
  HIGH_ICON,
  MEDIUM_ICON,
  LOW_ICON,
  INFORMATIONAL_ICON,
  COLLAPSE_CLOSE_ICON,
  UNKNOWN_ICON,
  ERROR_ICON,
  RISK_ICON,
  WARNING_CIRCLE_ICON,
  INFORMATION_ICON,
  RISK,
  VARIANT,
  STATUS,
  SKELETON_VARIANT
} from "../../constants/common.constants";
import Chip from "../../components/chip/chip";

import Select from "../../components/select/select";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import TextArea from "../../components/textArea/textArea";
import IncidentDetailsCollapse from "./incidentDetailsCollapse";
import FormatDate from "../../formatDate/formatDate";
import SnackbarContext from "../../contexts/snackbar.context";
import {
  categoriesMapper,
  severitiesMapper
} from "../../mappers/incidentDetailsMappers";
import { IIncidentDetailsProps } from "./incidentDetails.types";
import SkeletonLoader from "../../components/skeleton/skeleton-loader";
import SessionContext from "../../contexts/session.context";
import { IncidentInfoProviderContext } from "../incidentInformationProvider";
import Button from "../../components/button/button";

function getErrorMessage(errorValues: any): string {
  const valuesArray: any = [];
  Object.keys(errorValues).forEach((val) => {
    if (String(errorValues[val] || "").trim() === "") {
      const capitalize = val[0].toUpperCase();
      valuesArray.push(capitalize + val.substring(1));
    }
  });
  if (valuesArray.length !== 0) {
    const errorCount = valuesArray.length;
    let message = "";
    switch (errorCount) {
      case 1:
        message = `${valuesArray[0]} is`;
        break;
      case 2:
        message = `${valuesArray[0]} and ${valuesArray[1]} are`;
        break;
      default:
        message = `${valuesArray.join(", ").replace(/,\s*$/, "")} are`;
        break;
    }
    message += " mandatory.";
    return message;
  }
  return "";
}

export default function IncidentDetails(props: IIncidentDetailsProps) {
  const {
    created,
    severities,
    categories,
    status,
    catalog,
    hostname,
    sensor,
    onCategorySelected,
    onCollapse,
    onExpand,
    onSaveUsername,
    onSaveTitle,
    onSaveDescription,
    onSeveritySelected,
    orgName,
    tags,
    close_reason,
    close_final_disposition,
    isLoadingData,
    formik: {
      values,
      touched,
      errors,
      handleChange,
      setFieldValue,
      dirty,
      resetForm
    }
  } = props;

  const { isSIEM, submitIncident } = useContext(IncidentInfoProviderContext);
  const [isLoading, setIsloading] = useState(false);

  const createdTimestamp = new FormatDate(created).dateWithTime;
  const { isMdrRole } = useContext(SessionContext);
  let statusText = status;
  if (!isMdrRole && status !== STATUS.closed) {
    statusText = STATUS.new;
  }
  const severityIcons: any = useMemo(
    () => ({
      [SEVERITY.critical]: CRITICAL_ICON,
      [SEVERITY.high]: HIGH_ICON,
      [SEVERITY.medium]: MEDIUM_ICON,
      [SEVERITY.low]: LOW_ICON,
      [SEVERITY.informational]: INFORMATIONAL_ICON,
      default: UNKNOWN_ICON
    }),
    []
  );

  const categoryIcons: any = useMemo(
    () => ({
      [RISK.dismissed]: INFORMATION_ICON,
      [RISK.malicious]: ERROR_ICON,
      [RISK.pup]: RISK_ICON,
      [RISK.suspicious]: WARNING_CIRCLE_ICON,
      default: UNKNOWN_ICON
    }),
    []
  );

  const { showSnackbar } = useContext(SnackbarContext);

  const [expandPanel, setExpandPanel] = useState(true);

  const severityIcon = (
    <Icon
      image={
        values.severity ? severityIcons[values.severity] : severityIcons.default
      }
      alt=""
    />
  );

  const riskIcon = (
    <Icon
      image={
        values.category
          ? categoryIcons[values.category?.split("-")[0]]
          : categoryIcons.default
      }
      alt=""
    />
  );

  const informationIcon = <Icon image={INFORMATION_ICON} alt="" />;

  const onCollapseDetails = () => {
    setExpandPanel(false);
    onCollapse();
  };

  const onExpandDetails = () => {
    setExpandPanel(true);
    onExpand();
  };

  const onUserNameKeyDown = (event: any) => {
    if (event.key === "Enter" && event.target.value.length) {
      onSaveUsername(event.target.value);
    }
  };

  const onTitleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (event.target.value.length) {
        onSaveTitle(event.target.value);
      } else {
        showSnackbar({
          text: "A title is mandatory",
          type: MESSAGE.info,
          icon: TEXT_WHITE_ICON
        });
      }
    }
  };

  const onDescriptionKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (event.target.value.length) {
        onSaveDescription(event.target.value);
      } else {
        showSnackbar({
          text: "A description is mandatory",
          type: MESSAGE.info,
          icon: TEXT_WHITE_ICON
        });
      }
    }
  };

  // category changeHandler
  const categoryChangeHandler = useCallback(
    (value: string) => {
      setFieldValue("category", value);
      if (!isSIEM) {
        onCategorySelected(value);
      }
    },
    [isSIEM, onCategorySelected, setFieldValue]
  );
  // Severity changeHandler
  const severityChangeHandler = useCallback(
    (value: string) => {
      setFieldValue("severity", value);
      if (!isSIEM) {
        onSeveritySelected(value);
      }
    },
    [isSIEM, onSeveritySelected, setFieldValue]
  );

  const submitForm = useCallback(async () => {
    const message = getErrorMessage(values);
    if (message) {
      showSnackbar({
        text: message,
        type: MESSAGE.info,
        icon: TEXT_WHITE_ICON
      });
    } else {
      setIsloading(true);
      await submitIncident(values);
      setIsloading(false);
      resetForm();
    }
  }, [resetForm, showSnackbar, submitIncident, values]);

  return (
    <>
      {expandPanel ? (
        <>
          <section className="incidentContainer__details__header incidentContainer__details__section incidentContainer__details__section--border-bottom">
            <SkeletonLoader
              loadingVar={isLoadingData}
              variant={SKELETON_VARIANT.title}
            >
              <h5>{orgName}</h5>
            </SkeletonLoader>
            <Icon image={COLLAPSE_CLOSE_ICON} onClick={onCollapseDetails} />
          </section>
          <section className="incidentContainer__details__created incidentContainer__details__section incidentContainer__details__section--column incidentContainer__details__section--border-bottom">
            <Input
              label="Created"
              value={createdTimestamp}
              width={WIDTH.max}
              readOnly
              isLoading={isLoadingData}
            />
          </section>
          <section className="incidentContainer__details__status incidentContainer__details__section incidentContainer__details__section--column incidentContainer__details__section--border-bottom">
            <label>Status</label>
            <SkeletonLoader
              loadingVar={isLoadingData}
              variant={SKELETON_VARIANT.chip}
            >
              <Chip
                variant={VARIANT.status}
                status={statusText}
                label={statusText}
              />
            </SkeletonLoader>
          </section>
          <section className="incidentContainer__details__category incidentContainer__details__section incidentContainer__details__section--column incidentContainer__details__section--border-bottom">
            <label>Category</label>
            <Field
              name="category"
              value={categories[values.category]}
              onChange={categoryChangeHandler}
              items={categoriesMapper(catalog.risk_category, categoryIcons)}
              isLoading={isLoadingData}
              isValid={touched.category && !errors.category}
              readOnly={
                !isMdrRole ||
                status === STATUS.closed ||
                status === STATUS.clientEscalation
              }
              component={Select}
            />
            <SkeletonLoader
              loadingVar={isLoadingData}
              variant={SKELETON_VARIANT.avatar}
              marginTop
            >
              <div className="incidentContainer__details__icon">{riskIcon}</div>
            </SkeletonLoader>
          </section>
          <section className="incidentContainer__details__severity incidentContainer__details__section incidentContainer__details__section--column incidentContainer__details__section--border-bottom">
            <label>Severity</label>
            <Field
              name="severity"
              value={severities[values.severity]}
              onChange={severityChangeHandler}
              items={severitiesMapper(severities, severityIcons)}
              isLoading={isLoadingData}
              isValid={touched.severity && !errors.severity}
              readOnly={
                !isMdrRole ||
                status === STATUS.closed ||
                status === STATUS.clientEscalation
              }
              iconColor
              component={Select}
            />
            <SkeletonLoader
              loadingVar={isLoadingData}
              variant={SKELETON_VARIANT.check}
              marginTop
            >
              <div className="incidentContainer__details__icon">
                {severityIcon}
              </div>
            </SkeletonLoader>
          </section>
          {!isSIEM && (
            <section className="incidentContainer__details__hostname incidentContainer__details__section incidentContainer__details__section--column">
              <Input
                label="Hostname"
                value={hostname}
                width={WIDTH.max}
                readOnly
                isLoading={isLoadingData}
              />
            </section>
          )}
          {isSIEM && (
            <section className="incidentContainer__details__hostname incidentContainer__details__section incidentContainer__details__section--column">
              <Input
                label="Sensor"
                value={sensor}
                width={WIDTH.max}
                readOnly
                isLoading={isLoadingData}
              />
            </section>
          )}
          <section className="incidentContainer__details__username incidentContainer__details__section incidentContainer__details__section--column">
            <Field
              label="Username"
              name="username"
              error={errors.username}
              onChange={handleChange}
              value={values.username}
              width={WIDTH.max}
              touched={touched.username}
              isLoading={isLoadingData}
              readOnly={!isMdrRole || status === STATUS.closed}
              component={Input}
              onKeyDown={!isSIEM ? onUserNameKeyDown : null}
            />
          </section>
          <section className="incidentContainer__details__title incidentContainer__details__section incidentContainer__details__section--column">
            <Field
              label="Title"
              name="title"
              error={errors.title}
              onChange={handleChange}
              value={values.title}
              width={WIDTH.max}
              touched={touched.title}
              isLoading={isLoadingData}
              readOnly={!isMdrRole || status === STATUS.closed}
              onKeyDown={!isSIEM ? onTitleKeyDown : null}
              component={Input}
            />
          </section>
          <section className="incidentContainer__details__description incidentContainer__details__section incidentContainer__details__section--column">
            <Field
              label="Description"
              name="description"
              error={errors.description}
              onChange={handleChange}
              value={values.description}
              width={WIDTH.max}
              touched={touched.description}
              isLoading={isLoadingData}
              readOnly={!isMdrRole || status === STATUS.closed}
              onKeyDown={!isSIEM ? onDescriptionKeyDown : null}
              component={TextArea}
            />
          </section>
          {isSIEM && (
            <>
              {close_reason && status === STATUS.closed && (
                <>
                  <section className="incidentContainer__details__tags incidentContainer__details__section incidentContainer__details__section--column">
                    {close_final_disposition && (
                      <>
                        <label>Final Disposition</label>
                        <Field
                          name="Final Disposition"
                          value={close_final_disposition}
                          width={WIDTH.max}
                          isLoading={isLoadingData}
                          readOnly={status === STATUS.closed}
                          component={TextArea}
                        />
                        <br />
                      </>
                    )}
                    {isMdrRole && (
                      <div className="incidentContainer__details__tags__container">
                        <Chip
                          key={close_reason}
                          variant={VARIANT.status}
                          label={close_reason}
                        />
                      </div>
                    )}
                  </section>
                </>
              )}
            </>
          )}
          {isMdrRole && !isSIEM && (
            <>
              <section className="incidentContainer__details__tags incidentContainer__details__section incidentContainer__details__section--column">
                <label>Tags</label>
                <SkeletonLoader
                  loadingVar={isLoadingData}
                  variant={SKELETON_VARIANT.text}
                >
                  <div className="incidentContainer__details__tags__container">
                    {tags?.map((tag) => (
                      <Chip key={tag} variant={VARIANT.status} label={tag} />
                    ))}
                  </div>
                </SkeletonLoader>
              </section>
            </>
          )}
          {isMdrRole && isSIEM && statusText !== STATUS.closed && (
            <>
              <section className="incidentContainer__details__save incidentContainer__details__section incidentContainer__details__section--column">
                <div className="incidentContainer__details__save__container">
                  <SkeletonLoader
                    loadingVar={isLoadingData}
                    variant={SKELETON_VARIANT.text}
                  >
                    <Button onClick={submitForm} disabled={!dirty || isLoading}>
                      Save changes
                    </Button>
                  </SkeletonLoader>
                </div>
              </section>
            </>
          )}
        </>
      ) : (
        <IncidentDetailsCollapse
          status={status}
          category={categories[values.category]}
          severity={severities[values.severity]}
          riskIcon={riskIcon}
          severityIcon={severityIcon}
          informationIcon={informationIcon}
          onExpand={onExpandDetails}
        />
      )}
    </>
  );
}
