/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import {
  STATUS,
  TABLE,
  DATE_DEFAULT_VALUE,
  MESSAGE,
  ERROR_ICON,
  USER_ICON,
  WRONG_DATA_INITIALS,
  UNAUTHORIZED_ERROR_CODE,
  SUCCESS_ICON
} from "../constants/common.constants";
import {
  useAssignPantherIncident,
  useGetPantherIncidents,
  useGetNextPantherIncidentStatus,
  useGetPantherIncidentCatalog,
  useUpdatePantherIncident
} from "../api/incidents";
import useTableInstance from "../components/table/useTableInstance";
import useIncidentsTableColumns from "../incident-list/components/useIncidentsTableColumns";
import useCatalog from "../organizations/organization/useCatalog";
import FormatDate from "../formatDate/formatDate";
import IncidentListfiltersContext from "./incident-list-filters.context-panther";
import { incidentsListParametersMapper } from "../mappers/incidentListMappers";
import SnackbarContext from "./snackbar.context";
import { getInitials } from "../utils/string.utils";
import SessionContext from "./session.context";
import useIdle from "../hooks/useIdle";
import Permissions from "../permissions/permissions";
import {
  IAnyPropertyNameAndStringValue,
  IProvider
} from "../types/common.types";
import {
  IIncident,
  IIncidentCatalog,
  IPantherEvent,
  IPantherIncidentListContext
} from "./types/incident.list.types";
import ModalContext from "./modal.context";
import CloseIncidentForm from "../panther-incident-list/closeIncidentForm";

const IncidentListContext = createContext<IPantherIncidentListContext>(
  {} as IPantherIncidentListContext
);

export function PantherIncidentListProvider({ children }: IProvider) {
  const [incidentCatalog, setIncidentCatalog] = useState<IIncidentCatalog>(
    {} as IIncidentCatalog
  );
  const [loading, setLoading] = useState(false);
  // const [loadingDownloadData, setLoadingDownloadData] = useState(false);
  const [incidents, setIncidents] = useState<IPantherEvent[]>([]);
  const [selectedIncidents, setSelectedIncidents] = useState(new Map());
  const [pages, setPages] = useState({
    current: 1,
    total: 0
  });
  const [rows, setRows] = useState({
    start: 0,
    finish: 0,
    total: 0
  });

  const getIncidentsInProgress = useRef(false);

  const { isMdrRole, isProviderRole, handleLogOut } =
    useContext(SessionContext);
  const localUser = useSelector((state: any) => state.user.profile);
  const assign = useAssignPantherIncident();
  const getPantherEvents = useGetPantherIncidents();
  const getNextStatus = useGetNextPantherIncidentStatus();
  const getIncidentCatalog = useGetPantherIncidentCatalog();
  const { catalog } = useCatalog();
  const updateIncident = useUpdatePantherIncident();
  const checkUserActivity = useIdle();
  const { Incidents: permissions } = Permissions();

  const filters = useContext(IncidentListfiltersContext);
  const { setAsyncLoading, closeModal, showModal } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { currentFilters, filtersChanged, setFiltersChanged, searchText } =
    useContext(IncidentListfiltersContext);

  const columns = useIncidentsTableColumns();

  const tableRows = useMemo(
    () => (loading ? [] : incidents),
    [incidents, loading]
  );
  const tableColumns = useMemo(() => {
    if (isMdrRole) return columns.pantherStructure;

    if (isProviderRole) return columns.providerStructure;

    return columns.clientStructure;
  }, [tableRows]); // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTableInstance(tableColumns, tableRows);

  const handlers = useMemo(() => {
    if (!Reflect.has(catalog, "incident_handlers")) {
      return {};
    }

    return catalog.incident_handlers;
  }, [catalog]);

  const closeReasons = useMemo(
    () =>
      Reflect.has(incidentCatalog, "close_reason")
        ? Object.entries(incidentCatalog.close_reason)
        : [],
    [incidentCatalog]
  );

  const areRowsSelected = useMemo(
    () => !!selectedIncidents.size,
    [selectedIncidents]
  );

  const showCloseAction = useMemo(
    () =>
      permissions.sections.actionBar.close.canSee(localUser.role) &&
      areRowsSelected,
    [areRowsSelected, localUser.role, permissions.sections.actionBar.close]
  );

  const getNextStatusFromState = useCallback(
    (tableInstance: any, rowIndex: string) => {
      const cellState = tableInstance.getCellState(
        +rowIndex,
        TABLE.INCIDENTS.CELLS.MDR_USERS.status.index
      );

      const status =
        Reflect.has(cellState, "data") && Reflect.has(cellState.data, "next");

      return status ? cellState.data.next : null;
    },
    []
  );

  const closeAllowed = useCallback(
    (tableInstance: any, rowIndex: string) => {
      const status = getNextStatusFromState(tableInstance, rowIndex);

      if (!status) {
        return false;
      }

      return (
        Reflect.has(status, "values") &&
        Reflect.has(status.values, STATUS.closed)
      );
    },
    [getNextStatusFromState]
  );

  const onSelectCheck = useCallback(
    ({ rowId, eventId }: { rowId: string; eventId: string }) => {
      if (!closeAllowed(table, rowId)) {
        return;
      }
      setSelectedIncidents((prevState) => {
        const newMap = new Map(prevState);
        newMap.set(eventId, rowId);
        return newMap;
      });
    },
    [closeAllowed, table]
  );

  const uncheckAllIncidents = useCallback(() => {
    setSelectedIncidents(new Map());
    table.unSelectAllRows();
  }, [table]);

  const getClientUsersData = (incident: IIncident) => {
    const {
      organization_name,
      risk,
      category,
      severity,
      title,
      sensor,
      id,
      created,
      updated,
      status
    } = incident;

    return {
      org_name: organization_name,
      severity,
      category,
      title,
      sensor,
      id,
      created,
      updated,
      status,
      risk
    };
  };

  const loadPagingInformation = useCallback((headers: any) => {
    let page = headers.get("x-page");
    page = page ? Number(page) : 1;

    const rowsPerPage = headers.get("x-per-page");

    let totalRows = headers.get("x-total-count");
    totalRows = totalRows ? Number(totalRows) : 0;

    const rowsLastPage = totalRows % rowsPerPage;

    let incidentPages = Math.floor(totalRows / rowsPerPage);
    incidentPages += rowsLastPage ? 1 : 0;

    const rowsStart = totalRows
      ? page === 1
        ? 1
        : (page - 1) * rowsPerPage + 1
      : 0;
    const rowsFinish = totalRows
      ? page < incidentPages
        ? page * rowsPerPage
        : totalRows
      : 0;

    setPages({
      current: page,
      total: incidentPages
    });

    setRows({
      start: rowsStart,
      finish: rowsFinish,
      total: totalRows
    });
  }, []);
  const [nextStatusesResponses, setNextStatusesResponses] =
    useState<IAnyPropertyNameAndStringValue>(
      {} as IAnyPropertyNameAndStringValue
    );

  const getNextStatusForIncidents = useCallback(
    async (incidentArray: any) => {
      if (isMdrRole) {
        if (incidentArray.length) {
          const promises = incidentArray.map((incident: any) => {
            const { id } = incident;
            return getNextStatus(id).then((result) => ({ [id]: result }));
          });

          Promise.all(promises).then((arrOfResults) => {
            const mappedStatus = arrOfResults?.reduce((acc, obj: any) => {
              const key = Object.keys(obj)[0];
              acc[key] = obj[key].status;
              return acc;
            }, {});
            setNextStatusesResponses(mappedStatus);
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [incidents]
  );

  const getIncidentStatus = useCallback(
    (id: string) => nextStatusesResponses[id] || {},
    [nextStatusesResponses]
  );

  const loadData = useCallback(
    async (apiResponse: any) => {
      const { headers } = apiResponse;
      if (currentFilters?.creationStartDate?.caption === DATE_DEFAULT_VALUE) {
        const creationStartDate = headers.get("creation-start-date");
        if (creationStartDate) {
          const date = new FormatDate(parseInt(creationStartDate, 10));
          filters.setCreationStartDateFilter(date.epochDate, date.date);
        }
      }

      loadPagingInformation(headers);

      const jsonData = await apiResponse.json();
      const apiData = jsonData || [];

      const incidentRows = apiData.map((incident: IPantherEvent) => {
        const {
          handler_name,
          created,
          updated,
          organization_name,
          idm_organization_id
        } = incident;
        if (isMdrRole) {
          const initials = getInitials(handler_name);

          return {
            ...incident,
            org_name: organization_name,
            org_id: idm_organization_id,
            created,
            updated,
            handler_name: initials === WRONG_DATA_INITIALS ? "" : initials,
            handler_fullname: handler_name
          };
        }
        return getClientUsersData(incident);
      });
      setIncidents(incidentRows);
      await getNextStatusForIncidents(incidentRows);
      setLoading(false);

      filters.updateFilters();
    },
    // since getClientUsersData is a static method
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentFilters?.creationStartDate?.caption,
      loadPagingInformation,
      getNextStatusForIncidents,
      filters,
      isMdrRole
    ]
  );

  const load = useCallback(
    async (page = pages.current) => {
      if (getIncidentsInProgress.current) {
        return;
      }
      getIncidentsInProgress.current = true;
      setLoading(true);

      try {
        const response = await getPantherEvents(
          incidentsListParametersMapper(currentFilters, page, searchText)
        );
        loadData(response);
        getIncidentsInProgress.current = false;
      } catch (error: any) {
        console.error(
          `Error getting incidents. Status ${error.status}. ${error}`
        );
        setLoading(false);
        getIncidentsInProgress.current = false;
        if (error.status === UNAUTHORIZED_ERROR_CODE) handleLogOut();
      }
    },
    [
      pages,
      getPantherEvents,
      currentFilters,
      searchText,
      loadData,
      handleLogOut
    ]
  );

  useEffect(() => {
    setFiltersChanged(false);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersChanged]);

  const onAssign = useCallback(
    async ({ rowIndex, incidentId, previousHandlerId, handlerId }: any) => {
      const name = handlers[handlerId];
      const body: any = {
        handler_id: handlerId
      };

      if (!previousHandlerId) {
        body.status = STATUS.claimed;
      }
      try {
        const initials = getInitials(name);
        const response = await assign(incidentId, body);
        setIncidents((prevState) => {
          const newData = [...prevState];
          newData[rowIndex].status = response.status;
          newData[rowIndex].handler_name = initials;
          newData[rowIndex].handler_fullname = name;
          newData[rowIndex].handler_id = handlerId;
          return newData;
        });

        showSnackbar({
          text: `Incident assigned to ${response.handler_name}`,
          type: MESSAGE.info,
          icon: USER_ICON
        });

        return {
          incidentId,
          name
        };
      } catch (error) {
        showSnackbar({
          text: "Error assigning incident",
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        throw error;
      }
    },
    [assign, handlers, showSnackbar]
  );

  const closeSingle = useCallback(
    async (reason: string, id: string, closeFinalDisposition: string) => {
      try {
        setAsyncLoading(true);
        const closeReasonsFromCatalog = incidentCatalog.close_reason;
        const body = {
          status: STATUS.closed,
          close_reason: reason,
          close_final_disposition: closeFinalDisposition
        };
        await updateIncident(id, body);
        // reload table info instead of doing table manipulation
        load();
        showSnackbar({
          text: `Incident closed as ${closeReasonsFromCatalog[reason]}`,
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
        setAsyncLoading(false);
        closeModal();
      } catch (error: any) {
        console.error(
          `Error closing incident ${id} with reason ${reason}. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: `Error closing incident. ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        setAsyncLoading(false);
        closeModal();
      }
    },
    [
      setAsyncLoading,
      incidentCatalog.close_reason,
      updateIncident,
      load,
      showSnackbar,
      closeModal
    ]
  );

  const onClickClose = useCallback(
    (data: any) => {
      const { value, id } = data;
      const closeReason = closeReasons.filter(
        ([reason]) => reason === value || reason === data
      )[0][1];

      const text = `Are you sure you want to close this item as ${closeReason}`;
      showModal({
        title: "Close incident",
        actionText: "Yes, close",
        isForm: true,
        formId: "closeIncidentForm",
        content: (
          <CloseIncidentForm
            onSubmit={({ closeFinalDisposition }: any) => {
              closeSingle(value, id, closeFinalDisposition);
            }}
            initialValues={{
              closeFinalDisposition: ""
            }}
            confirmMessage={text}
          />
        )
      });
    },
    [closeReasons, showModal, closeSingle]
  );

  const handleGotoPage = useCallback((page: number) => load(page), [load]);

  const handlePreviousPage = useCallback(
    () => handleGotoPage(pages.current - 1),
    [handleGotoPage, pages]
  );

  const handleNextPage = useCallback(
    () => handleGotoPage(pages.current + 1),
    [handleGotoPage, pages]
  );

  useEffect(() => {
    getIncidentCatalog()
      .then((catalogData) => setIncidentCatalog(catalogData))
      .catch((error) =>
        console.error(
          `Error getting incidents catalog. Status ${error.status}. ${error}`
        )
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkUserActivity(), []);

  return (
    <IncidentListContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        incidents,
        onAssign,
        load,
        isLoading: false,
        showCloseAction,
        handlers,
        onSelectCheck,
        uncheckAllIncidents,
        onClickClose,
        pages,
        rows,
        handleNextPage,
        handlePreviousPage,
        handleGotoPage,
        closeReasons,
        columns,
        table,
        tableRows,
        incidentCatalog,
        loading,
        areRowsSelected,
        closeAllowed,
        setIncidents,
        updateIncident,
        loadingDownloadData: false,
        handleAssign: "",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClickAssign: (event: any) => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onUnselectCheck: (event: any) => {},
        onCheckAllIncidents: () => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        closeSelectedIncidents: (reason) => new Promise(() => {}),
        loadDownloadData: () => {},
        setFiltersChanged,
        filtersChanged,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getIncidentStatus
      }}
    >
      {children}
    </IncidentListContext.Provider>
  );
}

export default IncidentListContext;
