import { IAnyPropertyNameAndStringValue } from "../types/common.types";

// TODO: STATUS should have a value and text properties to map what is handled by the API and what's displayed to the user
export const STATUS = Object.freeze({
  claimed: "Claimed", // The Incidents API handles this value in sentence case format
  clientEscalation: "ClientEscalation",
  clientViewed: "ClientViewed",
  closed: "Closed", // The Incidents API handles this value in sentence case format
  closeBenign: "benign",
  closeFalsePositive: "false-positive",
  danger: "danger",
  error: "error",
  fulfilled: "fulfilled",
  internalReview: "InternalReview",
  new: "New",
  pending: "pending",
  rejected: "rejected",
  review: "Review"
});

export const SENSORS_STATUSES: any = Object.freeze({
  online: "Online",
  offline: "Offline",
  isolated: "Isolated"
});

// This will be unified with STATUS
export const INCIDENT_FILTERS_MAP: any = {
  Claimed: "Assigned",
  ClientEscalation: {
    mdr: "Sent",
    nonMdr: "New"
  },
  Closed: "Closed",
  InternalReview: "Review",
  New: "New",
  ClientViewed: "Viewed"
};

export const VARIANT: IAnyPropertyNameAndStringValue = {
  primary: "primary",
  secondary: "secondary",
  status: "status",
  transparent: "transparent"
};

export const SKELETON_VARIANT: any = Object.freeze({
  shortText: "shortText",
  text: "text",
  avatar: "avatar",
  chip: "chip",
  check: "check",
  action: "action",
  input: "input",
  textArea: "textArea",
  select: "select",
  title: "title",
  event: "event"
});

// eslint-disable-next-line no-shadow
export enum Guards {
  initData = "initData",
  incidentCompleteDetails = "incidentCompleteDetails",
  organizationsList = "organizationsList",
  createOrganization = "createOrganization",
  usersList = "usersList",
  userDetails = "userDetails"
}

export const INVITATION_STATUS: any = Object.freeze({
  pending: "Pending",
  expired: "Expired"
});

export const SHAPE = Object.freeze({
  oval: "oval",
  circle: "circle"
});

export const WIDTH = Object.freeze({
  default: "Default",
  exact: "Exact",
  max: "Max"
});

export const FIELDS = Object.freeze({
  first_name: "first_name",
  last_name: "last_name",
  name: "name",
  phone_number_1: "phone_number_1",
  phone_number_2: "phone_number_2",
  email: "email",
  role: "role",
  role_id: "role_id"
});

export const MODE = Object.freeze({
  create: "create",
  read: "read",
  update: "update",
  delete: "delete"
});

export const ACTIONS = Object.freeze({
  add: "add",
  assign: "assign",
  close: "close",
  create: "create",
  delete: "delete",
  filter: "filter",
  hide: "hide",
  navigate: "navigate",
  read: "read",
  save: "save",
  search: "search",
  show: "show",
  submit: "submit",
  update: "update"
});

export const DATA = Object.freeze({
  all: "all"
});

// These have to match the names defined in Auth0
export const ROLES = Object.freeze({
  clientUser: "Client User",
  clientAdmin: "Client Admin",
  providerAdmin: "Provider Admin",
  providerUser: "Provider User",
  mdrUser: "MDR User",
  mdrAdmin: "MDR Admin"
});

export const ADMIN_ROLES = [
  ROLES.mdrAdmin,
  ROLES.clientAdmin,
  ROLES.providerAdmin
];

export const SOTERIA_ROLES = [ROLES.mdrAdmin, ROLES.mdrUser];

export const CLIENT_ROLES = [
  ROLES.clientAdmin,
  ROLES.providerAdmin,
  ROLES.clientUser,
  ROLES.providerUser
];

export const PROVIDER_WORD = "Provider";
export const CLIENT_WORD = "Client";

export const SCOPE = Object.freeze({
  global: "global",
  local: "local"
});

export const EVENTS = Object.freeze({
  assignIncident: "assignIncident",
  checkIncident: "checkIncident",
  checkAllIncidents: "checkAllIncidents",
  closeIncident: "closeIncident",
  uncheckIncident: "uncheckIncident",
  uncheckAllIncidents: "uncheckAllIncidents",
  beforeUnload: "beforeunload"
});

export const RISK = Object.freeze({
  dismissed: "dismissed",
  malicious: "malicious",
  pup: "pup",
  risky: "risky", // TODO: Unify with pup
  suspicious: "suspicious"
});
// These values match what the API returns in the incidents catalog

// TODO: when merging both event tables, make it work from the catalog endpoint
export const SEVERITY = Object.freeze({
  critical: 0,
  high: 1,
  medium: 2,
  low: 3,
  informational: 4
});

export const TABLE = Object.freeze({
  INCIDENTS: {
    CELLS: {
      MDR_USERS: {
        check: {
          index: 0,
          name: "",
          accessor: "check"
        },
        client: {
          index: 1,
          name: "Organization",
          accessor: "org_name"
        },
        severity: {
          index: 2,
          name: "Severity",
          accessor: "severity"
        },
        category: {
          index: 3,
          name: "Category",
          accessor: "category"
        },
        rules: {
          index: 4,
          name: "Rules",
          accessor: "rules"
        },
        hostname: {
          index: 5,
          name: "Hostname",
          accessor: "hostname"
        },
        sensor: {
          index: 5,
          name: "Sensor",
          accessor: "sensor"
        },
        id: {
          index: 6,
          name: "ID",
          accessor: "id"
        },
        assigned: {
          index: 7,
          name: "Assigned",
          accessor: "handler_name"
        },
        created: {
          index: 8,
          name: "Created",
          accessor: "created"
        },
        modified: {
          index: 9,
          name: "Modified",
          accessor: "updated"
        },
        status: {
          index: 10,
          name: "Status",
          accessor: "status"
        },
        actions: {
          index: 11,
          name: "Actions",
          accessor: "actions"
        },
        risk: {
          index: 12,
          name: "Risk",
          accessor: "risk"
        }
      },
      CLIENT_USERS: {
        severity: {
          index: 0,
          name: "Severity",
          accessor: "severity"
        },
        category: {
          index: 1,
          name: "Category",
          accessor: "category"
        },
        title: {
          index: 2,
          name: "Title",
          accessor: "title"
        },
        hostname: {
          index: 3,
          name: "Hostname",
          accessor: "hostname"
        },
        id: {
          index: 4,
          name: "ID",
          accessor: "id"
        },
        created: {
          index: 5,
          name: "Created",
          accessor: "created"
        },
        modified: {
          index: 6,
          name: "Modified",
          accessor: "updated"
        },
        status: {
          index: 7,
          name: "Status",
          accessor: "status"
        },
        risk: {
          index: 8,
          name: "Risk",
          accessor: "risk"
        }
      }
    }
  },
  SENSORS: {
    CELLS: {
      id: {
        index: 0,
        name: "Id",
        accessor: "id"
      },
      type: {
        index: 1,
        name: "Type",
        accessor: "type"
      },
      os: {
        index: 2,
        name: "OS",
        accessor: "os"
      },
      organization: {
        index: 3,
        name: "Organization",
        accessor: "org_name"
      },
      hostname: {
        index: 4,
        name: "Hostname",
        accessor: "Hostname"
      },
      internalIp: {
        index: 5,
        name: "Internal IP",
        accessor: "internal_ip"
      },
      externalIp: {
        index: 6,
        name: "External IP",
        accessor: "external_ip"
      },
      installed: {
        index: 7,
        name: "Installed",
        accessor: "installed"
      },
      lastConnection: {
        index: 8,
        name: "Last Connection",
        accessor: "last_connection"
      },
      tags: {
        index: 9,
        name: "Tags",
        accessor: "tags"
      },
      status: {
        index: 10,
        name: "Status",
        accessor: "status"
      }
    }
  }
});

export const CHECK = Object.freeze({
  checked: "checked",
  unchecked: "unchecked",
  indeterminate: "indeterminate"
});

export const STATE = Object.freeze({
  incident: 0,
  tooltip: 1,
  avatar: 2,
  status: 3,
  handlers: 4
});

export const MESSAGE = Object.freeze({
  info: "info",
  error: "error"
});

export const INCIDENTS_EVENTS = Object.freeze({
  COMMENTS: {
    id: "COMMENTS",
    text: "Comments"
  },
  DNS_REQUEST: {
    id: "DNS_REQUEST",
    text: "DNS"
  },
  EXISTING_PROCESS: {
    id: "EXISTING_PROCESS",
    text: "Process"
  },
  FILE_CREATE: {
    id: "FILE_CREATE",
    text: "File Create"
  },
  MODULE_LOAD: {
    id: "MODULE_LOAD",
    text: "Modload"
  },
  NEW_DOCUMENT: {
    id: "NEW_DOCUMENT",
    text: "New Document"
  },
  NEW_PROCESS: {
    id: "NEW_PROCESS",
    text: "Process"
  },
  NETWORK_CONNECTIONS: {
    id: "NETWORK_CONNECTIONS",
    text: "Network"
  },
  REGISTRY_CREATE: {
    id: "REGISTRY_CREATE",
    text: "Registry"
  },
  REGISTRY_WRITE: {
    id: "REGISTRY_WRITE",
    text: "Registry"
  },
  SENSITIVE_PROCESS_ACCESS: {
    id: "SENSITIVE_PROCESS_ACCESS",
    text: "Crossproc"
  },
  THREAD_INJECTION: {
    id: "THREAD_INJECTION",
    text: "Crossproc"
  }
});

export const EVENTS_DETAILS_LINE = Object.freeze({
  COMMAND_LINE: "Command line",
  REGISTRY_KEY: "Registry key",
  REGISTRY_VALUE: "Registry value",
  SOURCE_PROCESS: "Source process",
  DESTINATION_PROCESS: "Destination process"
});

export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NOT_FOUND = "Not_Found";

export const DEFAULT_ROWS_PER_PAGE = 15;
export const ORG_DEFAULT_ROWS_PER_PAGE = 15;

export const incidentCVSFieldsOrder = [
  TABLE.INCIDENTS.CELLS.MDR_USERS.id.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.client.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.severity.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.category.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.rules.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.hostname.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.created.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.modified.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.status.accessor,
  TABLE.INCIDENTS.CELLS.MDR_USERS.risk.accessor
];

export const sensorsCVSFieldsOrder = [
  TABLE.SENSORS.CELLS.id.accessor,
  TABLE.SENSORS.CELLS.type.accessor,
  TABLE.SENSORS.CELLS.os.accessor,
  TABLE.SENSORS.CELLS.organization.accessor,
  TABLE.SENSORS.CELLS.hostname.accessor,
  TABLE.SENSORS.CELLS.internalIp.accessor,
  TABLE.SENSORS.CELLS.externalIp.accessor,
  TABLE.SENSORS.CELLS.installed.accessor,
  TABLE.SENSORS.CELLS.lastConnection.accessor,
  TABLE.SENSORS.CELLS.tags.accessor,
  TABLE.SENSORS.CELLS.status.accessor
];

export const sensorsCVSFieldsHeaders = [
  {
    [TABLE.SENSORS.CELLS.id.accessor]: TABLE.SENSORS.CELLS.id.name,
    [TABLE.SENSORS.CELLS.type.accessor]: TABLE.SENSORS.CELLS.type.name,
    [TABLE.SENSORS.CELLS.os.accessor]: TABLE.SENSORS.CELLS.os.name,
    [TABLE.SENSORS.CELLS.organization.accessor]:
      TABLE.SENSORS.CELLS.organization.name,
    [TABLE.SENSORS.CELLS.hostname.accessor]: TABLE.SENSORS.CELLS.hostname.name,
    [TABLE.SENSORS.CELLS.internalIp.accessor]:
      TABLE.SENSORS.CELLS.internalIp.name,
    [TABLE.SENSORS.CELLS.externalIp.accessor]:
      TABLE.SENSORS.CELLS.externalIp.name,
    [TABLE.SENSORS.CELLS.installed.accessor]:
      TABLE.SENSORS.CELLS.installed.name,
    [TABLE.SENSORS.CELLS.lastConnection.accessor]:
      TABLE.SENSORS.CELLS.lastConnection.name,
    [TABLE.SENSORS.CELLS.tags.accessor]: TABLE.SENSORS.CELLS.tags.name,
    [TABLE.SENSORS.CELLS.status.accessor]: TABLE.SENSORS.CELLS.status.name
  }
];

export const DEFAULT_EDR_ID = "LC";
export const DEFAULT_QUOTA = 2;

export const DEV_ENV = "development";

export const INCIDENTS_TIMELINE_FILTERS = Object.freeze({
  SHOW_ALL: {
    id: "SHOW_ALL",
    text: "Show all"
  },
  SHOW_EVENTS: {
    id: "SHOW_EVENTS",
    text: "Show events"
  },
  SHOW_COMMENTS: {
    id: "SHOW_COMMENTS",
    text: "Show comments"
  }
});

export const MENU_DEFAULT_WIDTH = "16em";
export const MENU_MAX_HEIGHT = 360;

export const IMAGE_CDN = process.env.REACT_APP_CDN_IMAGES;

export const COPY_ICON = `${IMAGE_CDN}/copy.svg`;
export const EXPAND_ICON = `${IMAGE_CDN}/expand.svg`;
export const SUCCESS_ICON = `${IMAGE_CDN}/success.svg`;
export const ERROR_ICON = `${IMAGE_CDN}/error.svg`;
export const USER_ICON = `${IMAGE_CDN}/user.svg`;
export const INCIDENTS_ICON = `${IMAGE_CDN}/incidents.svg`;
export const PANTHER_INCIDENTS_ICON = `${IMAGE_CDN}/panther_logo-exported.svg`;
export const SEARCH_ICON = `${IMAGE_CDN}/search.svg`;
export const DOWNLOAD_ICON = `${IMAGE_CDN}/download.svg`;
export const SYNC_ICON = `${IMAGE_CDN}/sync.svg`;
export const EDIT_ICON = `${IMAGE_CDN}/edit.svg`;
export const DIVIDER_ICON = `${IMAGE_CDN}/divider.svg`;
export const MORE_ICON = `${IMAGE_CDN}/more.svg`;
export const ORGANIZATIONS_ICON = `${IMAGE_CDN}/organizations.svg`;
export const ADD_ICON = `${IMAGE_CDN}/add.svg`;
export const SETTINGS_ICON = `${IMAGE_CDN}/settingsBlack.svg`;
export const DELETE_ICON = `${IMAGE_CDN}/delete.svg`;
export const USERS_ICON = `${IMAGE_CDN}/users.svg`;
export const TEXT_WHITE_ICON = `${IMAGE_CDN}/text-white.svg`;
export const LOGO_ICON = `${IMAGE_CDN}/SoteriaDefenseMDR.svg`;
export const AVATAR_ADD = `${IMAGE_CDN}/avatarAdd.svg`;
export const SETTINGS_WHITE_ICON = `${IMAGE_CDN}/settings.svg`;
export const LOGOUT_ICON = `${IMAGE_CDN}/logOut.svg`;
export const CHEVRON_RIGHT_ICON = `${IMAGE_CDN}/chevronRight.svg`;
export const CHEVRON_DOWN_ICON = `${IMAGE_CDN}/chevronDown.svg`;
export const CRITICAL_ICON = `${IMAGE_CDN}/critical.svg`;
export const HIGH_ICON = `${IMAGE_CDN}/high.svg`;
export const MEDIUM_ICON = `${IMAGE_CDN}/medium.svg`;
export const LOW_ICON = `${IMAGE_CDN}/low.svg`;
export const INFORMATIONAL_ICON = `${IMAGE_CDN}/informational.svg`;
export const COLLAPSE_CLOSE_ICON = `${IMAGE_CDN}/collapseClose.svg`;
export const UNKNOWN_ICON = `${IMAGE_CDN}/unknown.svg`;
export const RISK_ICON = `${IMAGE_CDN}/risk.svg`;
export const WARNING_CIRCLE_ICON = `${IMAGE_CDN}/warningCircle.svg`;
export const INFORMATION_ICON = `${IMAGE_CDN}/information.svg`;
export const FILTER_ICON = `${IMAGE_CDN}/filter.svg`;
export const LOGIN_PROMPT_ICON = `${IMAGE_CDN}/soteriaLogo.svg`;
export const BOOKMARK_ICON = `${IMAGE_CDN}/bookmark.svg`;
export const BOOKMARK_ON_ICON = `${IMAGE_CDN}/bookmarkOn.svg`;
export const EDR_VIEW_ICON = `${IMAGE_CDN}/link.svg`;
export const LOCK_ICON = `${IMAGE_CDN}/lock.svg`;
export const SENSORS_ICON = `${IMAGE_CDN}/sensors.svg`;
export const GOOGLE_ICON = `${IMAGE_CDN}/google.svg`;
export const MACOS_ICON = `${IMAGE_CDN}/macos.svg`;
export const LINUX_ICON = `${IMAGE_CDN}/linux.svg`;
export const LIMA_CHARLIE_ICON = `${IMAGE_CDN}/limaCharlie.svg`;
export const CARBON_BLACK_ICON = `${IMAGE_CDN}/carbonBlack.svg`;
export const WINDOWS_ICON = `${IMAGE_CDN}/windows.svg`;
export const AWS_ICON = `${IMAGE_CDN}/aws.svg`;
export const OFFICE_ICON = `${IMAGE_CDN}/office.svg`;
export const DEFAULT_OS_ICON = `${IMAGE_CDN}/default_os.svg`;
export const MSDEFENDER_ICON = `${IMAGE_CDN}/msdefender.svg`;
export const ARROW_DOWN_SORT = `${IMAGE_CDN}/arrowDownSort.svg`;
export const GENERIC_EVENT = `${IMAGE_CDN}/genericEvent.svg`;

export const OS_ICON_MAP: any = {
  macos: MACOS_ICON,
  linux: LINUX_ICON,
  google: GOOGLE_ICON,
  windows: WINDOWS_ICON,
  aws: AWS_ICON,
  guard_duty: AWS_ICON,
  office365: OFFICE_ICON,
  lima_charlie: LIMA_CHARLIE_ICON,
  lc_event: LIMA_CHARLIE_ICON,
  carbon_black: CARBON_BLACK_ICON,
  msdefender: MSDEFENDER_ICON,
  default: DEFAULT_OS_ICON
};

export const SENSOR_TYPE_ICON_MAP: any = {
  ...OS_ICON_MAP,
  default: LIMA_CHARLIE_ICON
};

export const INCIDENT_URL_REGEX =
  /incidents\/[a-zA-Z\d]{8}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{12}\/?$/;
export const REDIRECT_SESSION_STORAGE = "mdr-redirect";

export const SELECT_DEFAULT_VALUE = "Select";
export const SELECT_CLEAR_VALUE = "All";
export const SELECT_NONE_VALUE = "None";
export const DATE_DEFAULT_VALUE = "yyyy-mm-dd";

export const PLUS_ONE_DAY_IN_SECONDS = 86399;

export const ADD_EVENT_FORM_ID = "addEvent";

export const NONE_TEXT_VALUE = "None";

export const WRONG_DATA_INITIALS = "??";

export const EXPIRATION_TIME_REF = "_expirationTime";

export const CONFIRMATION_TIME_REF = "_confirmationTime";

export const UNAUTHORIZED_ERROR_CODE = 401;

export const UNKNOWN_TEXT = "Unknown";

export const PREVIOUS_TEXT = "Previous";

export const NEXT_TEXT = "Next";

export const LIMA_CHARLIE_LABEL = "lima_charlie";

export const EVENT_CHARS_LIMIT = 500;

// when changing this value, make sure to update the css accordingly
export const MAX_LEVEL_DETAIL_LINE_DEPTH = 3;
