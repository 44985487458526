/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useMemo } from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";
import Select from "../../components/select/select";
import { SELECT_NONE_VALUE, WIDTH } from "../../constants/common.constants";
import Input from "../../components/input/input";
import {
  edrTypesMapper,
  organizationListDropdownMapper
} from "../../mappers/organizationMappers";
import { IConfigurationProps } from "../organization.types";
import Permissions from "../../permissions/permissions";
import OrganizationsContext from "../../contexts/organizations.context";

export default function Configuration(props: IConfigurationProps) {
  const infoClassRoot = useMemo(
    () => "organizationContainer__configuration__info",
    []
  );
  const { Organization } = Permissions();
  const localUser = useSelector((state: any) => state.user.profile);
  const { mappedOrganizationsList, loading } = useContext(OrganizationsContext);

  const {
    formik: { values, errors, touched, handleChange, setFieldValue },
    isLoadingData,
    edrTypes,
    isLoadingCatalog
  } = props;

  return (
    <section className="organizationContainer__configuration">
      <h3 className="organizationContainer__configuration__title">
        Config Information
      </h3>
      <div className={infoClassRoot}>
        <div className={`${infoClassRoot}__edr`}>
          <label>EDR config</label>
          <Field
            name="edr_type"
            value={edrTypes[values.edr_type]}
            onChange={(value: string) => setFieldValue("edr_type", value)}
            items={edrTypesMapper(edrTypes)}
            isLoading={isLoadingData || isLoadingCatalog}
            isValid={errors.edr_type}
            readOnly={
              !Organization.sections.configuration.edr.canUpdate(
                localUser.role
              ) || !Object.keys(edrTypes).length
            }
            component={Select}
          />
        </div>
        <div className={`${infoClassRoot}__quota`}>
          <Field
            label="Endpoint quota"
            name="quota"
            type="number"
            min="1"
            error={errors.quota}
            onChange={handleChange}
            value={values.quota}
            width={WIDTH.max}
            touched={touched.quota}
            isLoading={isLoadingData}
            readOnly={
              !Organization.sections.configuration.quota.canUpdate(
                localUser.role
              )
            }
            component={Input}
          />
        </div>
        <div className={`${infoClassRoot}__provider`}>
          <label>Managing organization</label>
          <Field
            name="provider"
            value={
              mappedOrganizationsList[values.provider] || SELECT_NONE_VALUE
            }
            onChange={(value: string) => setFieldValue("provider", value)}
            items={organizationListDropdownMapper(mappedOrganizationsList)}
            isLoading={isLoadingData || loading}
            isValid={errors.provider}
            readOnly={
              !Organization.sections.configuration.provider.canUpdate(
                localUser.role
              ) || !Object.keys(mappedOrganizationsList).length
            }
            component={Select}
          />
        </div>
      </div>
    </section>
  );
}
