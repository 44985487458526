import React, { useCallback, useContext } from "react";
import Icon from "../../components/icon/icon";
import Tooltip from "../../components/tooltip/tooltip";
import { INFORMATION_ICON } from "../../constants/common.constants";
import { IAlert } from "../event.types";
import ModalContext from "../../contexts/modal.context";

export default function Alerts({
  alerts,
  isMdrRole
}: {
  alerts: IAlert[];
  isMdrRole: boolean;
}) {
  const { closeModal, showModal } = useContext(ModalContext);
  const showRuleModal = useCallback(
    (ruleData: IAlert) => {
      const { name, severity = "", description, runbook } = ruleData;
      showModal({
        title: "Alert Details",
        hideCancel: true,
        clickAction: closeModal,
        actionText: "Close",
        content: (
          <div className="severity-event-info-container">
            <div>
              <h5>Name</h5>
              <p>{name}</p>
            </div>
            <br />{" "}
            <div>
              <h5>Severity</h5>
              <div className="severity-event-info-modal">
                <div
                  className={`incidentsSeverityBar incidentsSeverityBar--${String(
                    severity
                  ).toLowerCase()}`}
                />
                <span>{severity.toLowerCase()}</span>
              </div>
            </div>
            <br />{" "}
            <div>
              <h5>Description</h5>
              <p>{description}</p>
            </div>
            <br />{" "}
            <div>
              <h5>Runbook</h5>
              <p>{runbook}</p>
            </div>
          </div>
        )
      });
    },
    [closeModal, showModal]
  );
  return (
    <>
      {alerts.map((rule) => {
        if (isMdrRole) {
          return (
            <div
              key={rule.alert_id}
              className="panther-event-detail-list panther-event-detail-list--enable has-tooltip"
              onClick={() => {
                showRuleModal(rule);
              }}
            >
              <Icon image={INFORMATION_ICON} alt="details" />
              <Tooltip text="click for more details" right />
              <span>{rule?.title || ""}</span>
            </div>
          );
        }
        return (
          <div key={rule.alert_id} className="panther-event-detail-list">
            <span>{rule?.title || ""}</span>
          </div>
        );
      })}
    </>
  );
}
